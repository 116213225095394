.App {
  text-align: center;
}
body {
  background: url(bg.png) !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center center !important;
}
.gif__container {
  width: 100%;
}
.gif {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  height: auto;
  margin-top: 2vh;
  margin-bottom: 4vh;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.651) 0px 15px 25px;
}
.logo__image {
  width: 28%;
  margin-left: 36%;
  margin-right: 36%;
  height: auto;
  margin-bottom: 2vh;
  margin-top: 1vh;
  filter: drop-shadow(0 0 1rem rgb(37, 37, 37));
}
